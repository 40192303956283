<template>
  <div class="container p-0" v-if="isTokenNotValid">
    <h5>{{ $t('errors.token_expired_1') }}</h5>
    <p>{{ $t('errors.token_expired_2') }}</p>
  </div>
  <div class="container p-0" v-else-if="isLoaded">
    <div class="row">
      <div id="main-form">
        <div v-if="currentNAWStep < maxNAWStep">
          <p><b>{{ currentNAWStep }}</b>/{{ maxNAWStep + ' ' + $t('employee.naw') }}</p>
          <h1>{{ $t('employee.step_naw_' + currentNAWStep) }}</h1>
        </div>
        <div v-show="currentNAWStep == 1" id="step-1">
          <div class="row mt-2">
            <div class="col-6">
              <label for="postal-code">{{ $t('form.first_name') }}</label>
              <input type="text" id="naw-first-name" v-model="nawData.employee.first_name" @change="isValid" />
            </div>
            <div class="col-6">
              <label for="city">{{ $t('form.last_name') }}</label>
              <input type="text" id="naw-last-name" v-model="nawData.employee.last_name" @change="isValid" />
            </div>
          </div>
          <div class="row">
            <div class="col-6">
              <label for="street">{{ $t('form.street') }}</label>
              <input type="text" id="street" v-model="nawData.employee.address.street" @change="isValid" />
            </div>
            <div class="col-3">
              <label for="housenr">{{ $t('form.house_nr') }}</label>
              <input type="number" id="housenr" v-model="nawData.employee.address.house_number" @change="isValid" />
            </div>
            <div class="col-3">
              <label for="addition">{{ $t('form.suffix') }}</label>
              <input type="text" id="addition" class="optional" v-model="nawData.employee.address.prefix" />
            </div>
          </div>
          <div class="row">
            <div class="col-6">
              <label for="postal-code">{{ $t('form.postal_code') }}</label>
              <input type="text" id="postal-code" v-model="nawData.employee.address.postal_code" @change="isValid" />
            </div>
            <div class="col-6">
              <label for="city">{{ $t('form.city') }}</label>
              <input type="text" id="city" v-model="nawData.employee.address.place" @change="isValid" />
            </div>
          </div>
          <div class="row">
            <div class="col-4">
              <label for="email-2">{{ $t('form.email') }}</label>
              <input type="email" id="email-2" v-model="nawData.employee.email" @change="isValid" />
            </div>
            <div class="col-4">
              <label for="phone">{{ $t('form.phone') }}</label>
              <input type="tel" id="phone" v-model="nawData.employee.phone_number" @change="isValid" />
            </div>
            <div class="col-4">
              <label for="dob">{{ $t('form.dob') }}</label>
              <input type="date" id="dob" v-model="nawData.employee.birth_date" data-notinfuture="" @change="isValid" :data-placeholder="!nawData.employee.birth_date" />
            </div>
          </div>
          <div class="row">
            <div class="col-6">
              <label for="pob">{{ $t('form.pob') }}</label>
              <input type="text" id="pob" v-model="nawData.employee.birth_place" @change="isValid" />
            </div>
            <div class="col-6">
              <label for="bsn">{{ $t('form.bsn') }}</label>
              <input type="bsn" id="bsn-no-suggestions" v-model="nawData.employee.bsn_number" placeholder="000000000" @change="validateBSN($event.target)" />
            </div>
          </div>
          <div class="row">
            <div class="col-6">
              <label for="nationality">{{ $t('form.nationality') }}</label>
              <select id="nationality" v-model="nawData.employee.nationality" @change="isValid" :data-field="nawData.employee.nationality">
                <option :value="null" disabled>{{ $t('form.select') }}</option>
                <option v-for="nationality in nationalities" :key="nationality.id" :value="nationality.id"> {{ nationality.name }} </option>
              </select>
            </div>
            <div class="col-6">
              <label for="iban">{{ $t('form.iban') }}</label>
              <input type="iban" id="iban-no-suggestions" v-model="nawData.employee.iban" @change="validateIBAN($event.target)" />
            </div>
          </div>
          <div class="row">
            <div class="col-6">
              <label for="country">{{ $t('form.country') }}</label>
              <select id="country" v-model="nawData.employee.birth_country_id" @change="isValid" :data-field="nawData.employee.birth_country_id">
                <option :value="null" disabled>{{ $t('form.select') }}</option>
                <option v-for="country in countries" :key="country.id" :value="country.id"> {{ country.name }} </option>
              </select>
            </div>
            <div class="col-6">
              <label>{{ $t('form.gender') }}</label>
              <fieldset class="col-sm-4 btn-group w-100" :data-field="nawData.employee.gender">
                <input id='gt1' type="radio" name="gtype" class="btn-check" value="Male" v-model="nawData.employee.gender" @change="isValid" />
                <label for="gt1" class="btn btn-sm">{{$t('form.gender_male')}}</label>
                <input id='gt2' type="radio" name="gtype" class="btn-check" value="Female" v-model="nawData.employee.gender" @change="isValid" />
                <label for="gt2" class="btn btn-sm">{{$t('form.gender_female')}}</label>
                <input id='gt3' type="radio" name="gtype" class="btn-check" value="Undefined" v-model="nawData.employee.gender" @change="isValid" />
                <label for="gt3" class="btn btn-sm">{{$t('form.gender_na')}}</label>
              </fieldset>
            </div>
          </div>
        </div>
        <div v-show="currentNAWStep == 2" id="step-2">
          <p class="row"></p>
          <UploadModule
            :model="docTypeOne"
            title="Voorkant"
            description="van je identiteitsbewijs"
            type="pdf"
            :owner="'contractFile2'"
            ref="contractFileUpload2"
            @addFile="(e) => addNAWFile(e, 0)"
            @removeFile="removeNAWFile"
          />
          <UploadModule
            :model="docTypeTwo"
            title="Achterkant"
            description="van je identiteitsbewijs"
            type="pdf"
            :owner="'contractFile3'"
            ref="contractFileUpload3"
            @addFile="(e) => addNAWFile(e, 1)"
            @removeFile="removeNAWFile"
          />
          <div class="row">
            <p class="w-40 pt-2">{{ $t('employee.id_type') }}</p>
            <select id="doc-type" class="w-60" v-model="nawData.employee.identification_nmbrs_document_id" @change="isValid" :data-field="nawData.employee.identification_nmbrs_document_id">
              <option :value="null" disabled>{{ $t('form.select') }}</option>
              <option v-for="documentType in documentTypes" :key="documentType.id" :value="documentType.id">{{ documentType.description }}</option>
            </select>
          </div>
          <div class="row">
            <p class="w-40 pt-2">{{ $t('employee.id_type_enddate') }}</p>
            <input class="w-60" type="date" id="dob2" v-model="nawData.employee.identification_expiration_date" data-notinpast="" @change="isValid" :data-placeholder="!nawData.employee.identification_expiration_date" />
          </div>
        </div>
        <div v-show="currentNAWStep == 3" id="step-3">
          <p>{{ $t('employee.lhk_text') }}</p>
          <div class="row">
            <p class="col-sm-6 mb-0 pt-1">{{ $t('employee.lhk_question') }}</p>
            <fieldset class="col-sm-6 btn-group" :data-field="nawData.tax_compensation">
              <input id='lhk1' type="radio" name="lhk" class="btn-check" :value="1" v-model="nawData.tax_compensation" @change="isValid" />
              <label for="lhk1" class="btn btn-sm">{{$t('form.yes')}}</label>
              <input id='lhk2' type="radio" name="lhk" class="btn-check" :value="0" v-model="nawData.tax_compensation" @change="isValid" />
              <label for="lhk2" class="btn btn-sm">{{$t('form.no')}}</label>
            </fieldset>
          </div>
          <br />
          <template v-if="nawData.tax_compensation">
            <p>U kunt hieronder uw handtekening zetten.</p>
            <div class="perfect-signature">
              <VPerfectSignature :stroke-options="strokeOptions" ref="signaturePad" @onEnd="sign()" />
            </div>
            <button class="btn action-button ms-2" @click="clear('main')">{{$t('form.reset')}}</button>
          </template>
        </div>
        <div v-show="currentNAWStep == 4" id="step-4">
          <h1>{{ $t('employee.naw_ready_save') }}</h1>
          <p>{{ $t('employee.naw_ready_save_sub') }}</p>
        </div>
        <div v-show="currentNAWStep == 5" id="step-5">
          <h1>{{ $t('employee.naw_thank_you') }}</h1>
          <p>{{ $t('employee.naw_thank_you_sub') }}</p>
        </div>
      </div>
      <div id="progress-sidebar" class="col-5 p-4">
        <div class="position-sticky">
          <h2>{{ $t(('form.add'), [$t('navigation.employees')]) }}</h2>
          <ol>
            <li :class="getListClass(1)">{{ $t('employee.step_naw_1') }}</li>
            <li :class="getListClass(2)">{{ $t('employee.step_naw_2') }}</li>
            <li :class="getListClass(3)">{{ $t('employee.step_naw_3') }}</li>
            <li :class="getListClass(4)">{{ $t('employee.step_naw_4') }}</li>
          </ol>
        </div>
      </div>
    </div>
    <div id="footer-buttons" :class="'row justify-content-between step-' + currentNAWStep">
      <div class="col-6 text-start">
        <button type="button" class="btn px-5 secondary-button" @click="currentNAWStep--" v-if="currentNAWStep > 1 && currentNAWStep <= maxNAWStep" :disabled="sendingData">{{ $t('form.back') }}</button>
      </div>
      <div class="col-6 text-end">
        <button type="button" class="btn px-5 btn-success" v-if="currentNAWStep == maxNAWStep" @click="saveSection()" :disabled="sendingData">{{ $t('form.save') }}</button>
        <button type="button" class="btn px-5 action-button" @click="setNext" v-else-if="currentNAWStep <= maxNAWStep">{{ $t('form.next') }}</button>
      </div>
    </div>
  </div>

  <hr class="text-white">
</template>

<script>
  import UploadModule from '@/components/UploadModule'
  import contractService from '@/services/ContractService'
  import countryService from '@/services/CountryService'
  import nationalityService from '@/services/NationalityService'
  import employeeService from '@/services/EmployeeService'
  import documentTypeService from '@/services/DocumentTypeService'
  import fileService from '@/services/FileService'
  import IBAN from "iban";
  import moment from 'moment'
  import store from '@/store/user'
  import VPerfectSignature from "v-perfect-signature";
  import http from '@/http-common'


  export default {
    name: 'EmplyeeFillInNAW',
    components: {
      UploadModule,
      VPerfectSignature
    },
    data() {
      return{
        currentNAWStep: 1,
        maxNAWStep: 4,
        formSection: 1,
        countries: [],
        nationalities: [],
        documentTypes: [],
        docTypeOne: null,
        docTypeTwo: null,
        token: null,
        isTokenNotValid: false,
        sendingData: false,
        isLoaded: false,
        shouldGenerateContract: true,
        nawData: {
          "employee": {
            "email": "",
            "first_name": "",
            "initials": "",
            "prefix": null,
            "last_name": "",
            "birth_place": "",
            "birth_date": "",
            "nationality": null,
            "gender": "",
            "description": null,
            "bsn_number": "",
            "iban": "",
            "phone_number": "",
            "identification_number": null,
            "identification_expiration_date": null,
            "identification_uploaded": null,
            "birth_country_id": null,
            "company_id": null,
            "identification_file_id": null,
            "identification_nmbrs_document_id": null,
            "employee_status_id": null,
            "address": {
                "postal_code": "",
                "house_number": "",
                "prefix": null,
                "street": "",
                "place": "",
                "latitude": null,
                "longitude": null,
                "country_id": null
            },
          },
          "files": [null, null, null],
          "tax_compensation": 0,
          "tax_compensation_date": null,
          "tax_compensation_signature": null
        },
        contractData: {
          "employee": {
              "first_name": "",
              "last_name": "",
              "email": "",
              "phone_number": ""
          },
          "job_function": {
              "name": "",
              "reference_number": "",
              "department": "",
              "start_date": "",
              "fixed_term": null,
              "fixed_term_expiration_date": null,
              "fixed_term_expiration_month": null,
              "probation": 0,
              "probation_expiration_date": "",
              "probation_expiration_month": 0,
              "employment_type": "",
              "skilled_worker": null,
              "timetable": "8,8,8,8,8,0,0,8,8,8,8,8,0,0",
              "hours": 40,
              "salary": "",
              "travel_compensation_type": "",
              "tax_compensation": 0,
              "tax_compensation_days": null,
              "tax_compensation_distance": null,
              "additional_compensations": null,
              "travel_compensation": 0,
              "travel_compensation_days": null,
              "travel_compensation_distance": null,
              "expenses_compensation": null,
              "phone_compensation": null,
              "direct_upload": null
          },
          "generate_contract": null,
          "generate_date": null,
          "generate_place": null,
          "template_id": null,
          "document_view": null,
          "document": null,
          "signature": null,
          "sync": null,
          "company_id": null,
          "attachments": []
        },
        strokeOptions: {
          size: 4,
          thinning: 0.75,
          smoothing: 0.5,
          streamline: 0.5,
        }
      }
    },
    methods: {
      isValid(e){
        let el = e.target ? e.target : e;
        let shouldShowvalidity = true;
        let validityCheck = true;
        if(e.target) this.refreshContract();
        if(el.dataset.field){
          validityCheck = el.dataset.field;
        }
        else if(el.parentElement.dataset.field){
          el = el.parentElement;
          validityCheck = el.dataset.field;
        }
        else if(el.parentElement.parentElement.dataset.field){
          el = el.parentElement.parentElement;
          validityCheck = el.dataset.field;
        }
        else if(el.tagName === "SELECT" && el.value === this.$t('form.select')){
          validityCheck = false;
        }
        else{
          switch(el.type){
            case "email":
              //eslint-disable-next-line
              validityCheck = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(el.value);
              break;
            case "tel":
              validityCheck = /^\+?\d{8,13}$/.test( el.value.replaceAll(" ", "").replaceAll("-", "") );
              break;
            case "number":
              validityCheck = /^[0-9,.]+$/.test(el.value);
              break;
            case "date":
              validityCheck = /^\d{4}-\d{2}-\d{2}$/.test(el.value);
              if(validityCheck && (el.hasAttribute('data-notinpast') || el.hasAttribute('data-notinfuture'))){
                const date = new Date(el.value);
                if(el.hasAttribute('data-notinpast')){
                  validityCheck = date > Date.now();
                }
                else{
                  validityCheck = date < Date.now();
                }
              }
              break;
            case "file":
              break;
            default: {
              switch(el.id){
                case "postal-code":
                  validityCheck = /^\d{4}\s?[a-zA-Z]{2}$/.test(el.value);
                  break;
                case "bsn":
                  validityCheck = this.validateBSN(el);
                  shouldShowvalidity = false;
                  break;
                case "iban":
                  validityCheck = this.validateIBAN(el);
                  shouldShowvalidity = false;
                  break;
                default:
                  validityCheck = el.value;
                  break;
              }
            }
          }
        }

        if(shouldShowvalidity)
          this.showValidity(el, validityCheck);
        return validityCheck;
      },
      validate(){
        const steps = [0, 0, this.currentNAWStep, 0];
        const step = steps[this.formSection];
        const els = document.querySelectorAll(`#step-${step} input:not(.optional):not([type="radio"]), #step-${step} select:not(.optional), #step-${step} fieldset:not(.optional)`);
        let isValid = true;
        els.forEach(el => {
          if(!this.isValid(el)){
            isValid = false;
          }
        });
        if(!isValid){
          alert(this.$t('errors.empty_fields'));
        }
        else if(this.currentNAWStep == 2 && ( !this.nawData.files[0] )){
          alert(this.$t('errors.identity'));
          isValid = false;
        }
        else if(this.currentNAWStep == 3 && this.nawData.tax_compensation && !this.nawData.tax_compensation_signature ){
          alert(this.$t('errors.signature_missing'));
          isValid = false;
        }
        return isValid;
      },
      setNext(){
        if(!this.validate()) return;
        this.currentNAWStep++;
        window.scrollTo(0, 0);
      },
      sendNAWData(){
        this.sendingData = true;
        if (this.nawData.employee.address && this.nawData.employee.address.birth_place) {
          this.nawData.employee.birth_place = this.nawData.employee.address.birth_place;
        }
        if (this.nawData.employee.address && this.nawData.employee.address.country_id) {
          this.nawData.employee.country_id = this.nawData.employee.address.country_id;
        }
        if(this.nawData.files){
          this.nawData.files = this.setFiles(this.nawData.files);
        }
        if(this.token){
          employeeService.updateWithToken(this.token, this.nawData).then(() => {
            this.currentNAWStep++;
          }).catch(error => {
            this.toastError(error)
            console.log('error', error)
          });
        }
        else if(this.nawData.id){
          employeeService.update(this.nawData).then(() => {
            this.currentNAWStep++;
          }).catch(error => {
            this.toastError(error)
            console.log('error', error)
          }).finally(() => this.sendingData = false);
        }
        else{
          console.log('error');
        }
      },
      getCountries() {
          countryService.index(this.token).then(response => {
              this.countries = response.data;
              this.countries.sort(function(x,y){ return x.name =='Nederland'  ? -1 : y.name =='Nederland' ? 1 : 0; });
          }).catch(error => {
              console.log('error', error)
          })
      },
      getNationalities() {
          nationalityService.index(this.token).then(response => {
              this.nationalities = response.data;
              this.nationalities.sort(function(x,y){ return x.name =='Nederlandse'  ? -1 : y.name =='Nederlandse' ? 1 : 0; });
          }).catch(error => {
              console.log('error', error)
          })
      },
      getDocumentTypes(){
        documentTypeService.index(this.token).then(response => {
          this.documentTypes = response.data;
        }).catch(error => {
          console.log('error', error)
        })
      },
      getListClass(step){
        const currentStep = this.currentNAWStep;
        return step < currentStep ? "check" : (step == currentStep ? "active" : "inactive");
      },
      getSumBSN(bsn) {
        let a = parseInt(bsn[0]) * 9;
        let b = parseInt(bsn[1]) * 8;
        let c = parseInt(bsn[2]) * 7;
        let d = parseInt(bsn[3]) * 6;
        let e = parseInt(bsn[4]) * 5;
        let f = parseInt(bsn[5]) * 4;
        let g = parseInt(bsn[6]) * 3;
        let h = parseInt(bsn[7]) * 2;
        let i = parseInt(bsn[8]) * -1;

        let sum = a + b + c + d + e + f + g + h + i;
        return sum;
      },
      saveSection(){
        if(!this.validate()) return;
        this.sendNAWData();
      },


      clear() {
        this.$refs.signaturePad.clear();
      },
      sign() {
        this.nawData.tax_compensation_signature = this.$refs.signaturePad.toDataURL();
        this.nawData.tax_compensation_date = moment();
        this.nawData.tax_compensation = 1;
      },
      showValidity(el, isValid, showCheckmark = false){
        if(isValid){
          if(showCheckmark)
            el.classList.add('is-valid');
          el.classList.remove('is-invalid');
        }
        else{
          if(showCheckmark)
            el.classList.remove('is-valid');
          el.classList.add('is-invalid');
        }
      },
      validateBSN(userBSN) {
        let bsn = ("000000000" + userBSN.value).slice(-9);

        let valid = true;
        if (!userBSN.value || isNaN(userBSN.value) || Number(userBSN.value) == 0 || this.getSumBSN(bsn) % 11) {
          valid = false;
        }

        this.showValidity(userBSN, valid, true);
        return valid;
      },
      validateIBAN(userIBAN) {
        if(!this.nawData.employee.iban){
          this.showValidity(userIBAN, false, true);
          return false;
        }
        this.nawData.employee.iban = this.nawData.employee.iban.toUpperCase().replaceAll(/[^A-Z0-9]/g, '');
        let valid = userIBAN.value && IBAN.isValid(this.nawData.employee.iban);
        this.showValidity(userIBAN, valid, true);
        return valid;
      },
      refreshContract(){
        this.shouldGenerateContract = true;
      },

      downloadPDF(item) {
        this.item = {...item};
        contractService.download(this.item.id).then(response => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement("a");
            link.href = url;
            const pdfName = item.employee.first_name.replace(' ', '-') + '_' + item.employee.last_name.replace(' ', '-') + "_" + item.generate_date;
            link.setAttribute("download", pdfName + ".pdf");
            document.body.appendChild(link);
            link.click();
        }).catch(error => {
            this.toastError(error)
        });

      },
      addNAWFile(file, index){
        fileService.storeWithToken(file, this.token).then(response => {
          this.nawData.files[index] = response.data;
        });
      },
      removeNAWFile(index){
        this.nawData.files[index] = null;
      },
      setFiles(fileData){
        let files = fileData;
        const size = files.length - 1;
        for(let i = size; i >= 0; i--){
          if(files[i] == null) files.splice(i, 1);
        }
        return files;
      }
    },
    mounted() {
      http.defaults['headers']['Authorization'] = store.getters.getAuthHeader

      this.formSection = 2;
      this.currentNAWStep = 1;
      const href = window.location.href;
      this.token = href.includes('?token=') ? href.split('?token=')[1] : null;
      
      if(this.token){
        employeeService.showWithToken(this.token).then(response => {
          console.log(response.data, this.nawData);
          this.nawData = response.data;
          this.nawData.files = [null, null, null];
          this.isLoaded = true;
        }).catch(error => {
            this.isTokenNotValid = true;
        });
      }
      else{
        this.isTokenNotValid = true;
      }

      //Get countries and nationalities
      this.getCountries();
      this.getNationalities();
      this.getDocumentTypes();
    }
  }
</script>

<style scoped>
@media (min-width: 768px) {
  #main-form{
    border-right: 1px solid var(--dp-primary-color);
    padding: 1.5rem;
    flex: 0 0 auto;
    width: 58.33333333%;
    min-height: 400px
  }
}
@media (max-width: 768px) {
  #progress-sidebar{
    display: none
  }
  #footer-buttons.step-1, #footer-buttons.step-2{
    margin-top: 1.5rem;
    margin-bottom: 1rem
  }
  #footer-buttons:not(.step-1):not(.step-2){
    position: fixed;
    bottom: 4px;
    width: 100vw
  }
  #main-form .row > div{
    width: 100% !important
  }
}
</style>
import {ApiService} from '@/services/ApiService'
import http from '@/http-common'
import store from '@/store/user'

        
const URL = '/api/employee'

class EmployeeService extends ApiService {
    constructor(){
        super()
    }
    getUrl(){
        return URL
    }
    getStatuses(){
        return http.get(`${URL}/status`);
    }
    indexByCompany(filters, search, pageNumber = 1, employeeFilters = 0){
        var queryUrl = ''   
        if(store.getters.getDomain){
            queryUrl = `&domain=` + store.getters.getDomain
        }
        if(employeeFilters !== 0){
            let extraParams = "";
            if(employeeFilters.employee_status_id) extraParams += ("&employee_status_id=" + employeeFilters.employee_status_id);
            if(employeeFilters.employment_type) extraParams += ("&employment_type=" + employeeFilters.employment_type);
            if(search) extraParams += ("&search=" + encodeURIComponent(search));
            return http.get(`/api/nmbrs/soap/${filters.customer_id}/employee?company_id=${filters.company_id}&page=${pageNumber}${extraParams}${queryUrl}`);
        }
        return http.get(`${URL}?company_id=${filters.company_id}${queryUrl}`);
    }
    mail(data){
        return http.post(`api/contract/mail-hr`, data)
    }
    showFromNMBRS(id, company_id, customer_id){
        var queryUrl = ''   
        if(store.getters.getDomain){
            queryUrl = `&domain=` + store.getters.getDomain
        }
        return http.get(`/api/nmbrs/soap/${customer_id}/employee/${id}?company_id=${company_id}${queryUrl}`)
    }
    updateNMBRS(customer_id, data){
        var queryUrl = ''   
        if(store.getters.getDomain){
            queryUrl = `?domain=` + store.getters.getDomain
        }
        return http.put(`/api/nmbrs/soap/${customer_id}/employee/${data['external_id']}${queryUrl}`, data)
    }
    sync(contract_id, customer_id){
        return http.post(`/api/contract/${contract_id}/customer/${customer_id}/export-soap`, [])
    }
    signrequest(contract_id){
        return http.post(`api/signrequest/${contract_id}`, [])
    }
    showWithToken(token) {
        return http.get(`api/contract-external?token=${token}`)
    }
    updateWithToken(token, data) {
        return http.put(`api/contract-external?token=${token}`, data)
    }
}

export default new EmployeeService()
<template>
  <div class="dragdropupload" @dragover="dragover" @dragleave="dragleave" @drop="drop($event)">
        <input type="file" multiple name="fields[assetsFieldHandle][]" :id="'assetsFieldHandle-'+title" class="input-drag-and-drop opacity-0 overflow-hidden absolute" @change="onChange(type)" ref="file" accept=".pdf,.jpg,.jpeg,.png,.doc,.docx" />
        <label  v-if="!this.files.length && !this.model" :for="'assetsFieldHandle-'+title" class="block cursor-pointer">
          <p>{{ title }}</p>
          <div class="fw-normal file-description" v-html="description"></div>
        </label>
        <ul v-if="this.files.length" v-cloak>
          <li class="text-sm p-1" v-for="file in files" v-bind:key="file">
              <img class="uploaded-file-preview" :src="readFile(file)" :alt="file.name" /> {{ file.name }} 
              <button class="ml-2 btn btn-secondary fileDeleteButton" type="button" @click="remove(files.indexOf(file))" title="Remove file">Verwijder</button>
          </li>
        </ul>
        <ul v-else-if="this.model" v-cloak>
          <li class="text-sm p-1">
              <span class="download" @click="download"><i class="fa fa-file"></i> &ensp; {{ model.name }} </span>
              <button class="ml-2 btn btn-secondary fileDeleteButton" type="button" @click="remove(0)" title="Remove file">Verwijder</button>
          </li>
        </ul>
  </div>
</template>

<style scoped>
.dragdropupload ul {
  list-style: none;
} 
</style>

<script>
//import uploadService from '@/services/contact/modelService'
import fileService from '@/services/FileService'
import pdfImage from "@/assets/pdf.png"
import moment from 'moment'

export default {
  name: 'UploadModule',
  data(){
    return {
        files: [],
        pdfImage: pdfImage,
    }
  },
  emits: ["filesUploaded", "addFile"],
  props: { 
    model: {},
    title: {},
    description: {},
    type: {},
    owner: {}
  },
  methods: {
    async submitFiles () {
        const file = this.files[0];
        if (file) {
          const fileSizeLimit = 50 * 1024 * 1000;
          if(file.size > fileSizeLimit){
              this.$toast.error("Dit bestand is te groot. Max grootte: 50MB");
              this.files.pop();
              return;
          }
          if(file.name.match(/\.doc?$|\.docx?$|\.xls?$|\.xlsx?$|\.pdf?$|\.eml?$|\.msg?$|\.png?$|\.gif?$|\.jpg?$|\.jpeg?$|\.bmp?$|\.tif?$|\.tiff$/)){
              this.addFileItem(file)
          }
          else {
              this.$toast.error("Dit bestandstype wordt niet ondersteund");
              this.files.pop();
          }
        }
    },
    async addFileItem(file) {
        let fileSize = Math.ceil(file.size / 1024) + ' kb';
        let fileType = file.type.split('/')[1];
        this.$emit('addFile', {
            'type': fileType,
            'name': file.name,
            'size': fileSize,
            'date': moment(),
            'blob': await this.blobToBase64(file)
        });
    },
    async blobToBase64(blob) {
        return new Promise((resolve, _) => {
            const reader = new FileReader();
            reader.onloadend = () => resolve(reader.result.substr(reader.result.indexOf(',')+1));
            reader.readAsDataURL(blob);
        });
    },
    handleFilesUpload(){
        this.files = this.$refs.files.files
    },
    onChange() {
      this.files.push(...this.$refs.file.files)
      //if (this.owner == 'contact') {
        this.submitFiles()
      //}
    },
    remove(index) {
      this.files.splice(index, 1);
      this.$emit('removeFile');
    },
    readFile(file){
      if (file.type == "application/pdf") {
        return this.pdfImage
      } else {
        return URL.createObjectURL(file)
      }
    },
    dragover(event) {
      event.preventDefault()
      // Add some visual fluff to show the user can drop its files
      if (!event.currentTarget.classList.contains('bg-green-300')) {
        event.currentTarget.classList.remove('bg-gray-100')
        event.currentTarget.classList.add('bg-green-300')
      }
    },
    dragleave(event) {
      // Clean up
      event.currentTarget.classList.add('bg-gray-100')
      event.currentTarget.classList.remove('bg-green-300')
    },
    drop(event) {
      event.preventDefault();
      this.$refs.file.files = event.dataTransfer.files
      this.onChange() // Trigger the onChange event manually
      // Clean up
      event.currentTarget.classList.add('bg-gray-100')
      event.currentTarget.classList.remove('bg-green-300')
    },
    download () {
      fileService.downloadFile(this.model.id, this.model.name).then(response => {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', this.model.name);
          document.body.appendChild(link);
          link.click();
      }).catch(error => {
          this.$toast.error('Kon bestand niet downloaden')
      });
    },
    downloadDocument (file) {
      /*uploadService.downloadFile(this.model.id, file.id).then(response => {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', file.name);
          document.body.appendChild(link);
          link.click();
      }).catch(error => {
          this.$toast.errro('Kon bestand niet downloaden')
      });*/
    },
    deleteDocument (file) {
      /*uploadService.deleteFile(this.model.id, file.id).then(response => {
          this.$parent.model.files = response.data.files
          this.$toast.success('Bestand is verwijderd')
      }).catch(error => {
          this.$toast.error('Kon bestand niet verwijderen')
      });*/
    }    
  }
}
</script>
<style scoped>
.file-drop-field{
  margin: auto;
  min-height: 200px;
  width: 70%;
  background-color: rgb(238, 238, 238);
}
.file-drop-field input{
  display: none;
}
[v-cloak] {
  display: none;
}
.uploaded-file-preview{
  height: 100px;
}
.fileDeleteButton {
  position: relative;
  top: 4px;
  left: 65px;
}
.download:hover{
  text-decoration: underline;
}
</style>
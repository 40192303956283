<template>

  <div class="container-fluid mt-4" v-if="this.$route.name === 'edit_employee_naw'">
    <header class="app-nav-header">
      <img src="./assets/ibeo-logo.svg" />
    </header>

    <main class="app-main-content no-sidebar">
      <NAWComponent />
    </main>
  </div>
  <div class="container-fluid mt-4" v-else-if="authStatus == 'logged_in'">
    <header class="app-nav-header" id="ibeo-main-header">
      <img src="./assets/ibeo-logo.svg" />
      <div class="w-100 text-end pe-2">
        <template v-if="showDomain">
          <i class="far fa-globe px-2"></i>
          <select id="environment-select" class="form-select" v-model="currentDomain" @change="changeDomain()">
            <option v-for="environment in environments" :key="environment.id" :value="environment.id"> {{environment.name}} </option>
          </select>
        </template>
        <i class="far fa-user px-2"></i>
        <select id="customer-select" class="form-select" v-model="currentCostumer" @change="getCompanies(true)">
          <option v-for="customer in customers" :key="customer.id" :value="customer.id"> {{customer.name}} </option>
        </select>
        <select id="company-select" class="form-select" v-model="currentCompany" @change="setCompany(false)" :disabled="companies.length == 0">
          <option v-for="company in companies" :key="company.id" :value="company.id"> {{company.name}} </option>
        </select>
      </div>
    </header>
    <nav id="sidebarMenu" class="app-nav-menu sidebar collapse">
      
        <nav-header />
      
    </nav>

    <main class="app-main-content">
      <router-view :key="$route.path"/>
    </main>
  </div>
  <div class="container-fluid" v-else-if="authStatus != 'checking'">
    <LoginComponent />
  </div>

</template>


<script>
  import NavHeader from '@/components/NavHeader.vue'
  import http from '@/http-common'
  import customerService from '@/services/CustomerService.js'
  import companyService from '@/services/CompanyService'
  import loginservice from '@/services/LoginService'
  import LoginComponent from '@/components/auth/LoginComponent.vue'
  import NAWComponent from '@/components/EmplyeeFillInNAW.vue'
  import store from '@/store/user'
  import router from '@/router'
  import * as Sentry from "@sentry/vue"

  export default {
    data(){
      return {
          environments: [
            {id: 'ifiac.nmbrs.nl', name: 'Ifiac'},
            {id: 'ibeo.nmbrs.nl', name: 'Ibeo'}
          ],
          customers: [],
          companies: [],
          currentDomain: null,
          currentCostumer: null,
          currentCompany: null,
          currentCostumerName: null,
          currentCompanyName: null,
          retroactivelySetFunctions: false,
          dispatchesFired: 0,
          amountOfDispatches: 4,
          currentUser: {
            is_hr: false,
            client_name: "IBEO Test",
            company_name: "Test bedrijf",
            customers: []
          },
          showDomain: false,
          authStatus: 'checking'
      }
    },
    watch: {
        $route: {
            immediate: true,
            handler(to) {
                document.title = process.env.VUE_APP_TITLE +' | ' + to.meta.title ;
            }
        },
    },
    components: {
      NavHeader,
      LoginComponent,
      NAWComponent
    },
    computed: {
      isLoggedIn() {
        let isLoggedIn = store.getters.isLoggedIn;
        if(isLoggedIn){
          Sentry.setUser({id: store.getters.userId, username: store.getters.getName});
        }
        console.log('isloggedin', isLoggedIn)
        return isLoggedIn;
      }
    },
    methods :{
      changeDomain(){
        store.dispatch('setDomain', this.currentDomain)
        store.dispatch('setCompany', null).then(r => this.setEmployeeOverview());
        store.dispatch('setCustomer',null).then(r => this.setEmployeeOverview());
        store.dispatch('setCompanyName', null).then(r => this.setEmployeeOverview());
        store.dispatch('setCustomerName', null).then(r => this.setEmployeeOverview());
        this.checkCredentials()
      },
      checkCredentials(shouldReload = true) {
        if(window.location.pathname.includes('password/reset')){
          this.authStatus = 'reset';
          return;
        }
        if (window.location.pathname != '/callback' && !this.checkIfEmployeeIsFillingInNAW()){
          loginservice.isLoggedIn().then(response => {
            if (!response.data) {
              store.dispatch('userLogout')
              if (window.location.pathname != '/') {
                router.push('/')
              }
              this.authStatus = 'unauthorized'
            } else {
              this.authStatus = 'logged_in';
              this.currentUser.is_hr = store.getters.getIsHR;
              this.showDomain = this.currentUser.is_hr;
              let linkedCustomers = store.getters.getCustomers;
              let queryParam = this.currentUser.is_hr ? {} : {'linked_customers': linkedCustomers.join(',')};
              customerService.index(null, queryParam).then(response => {
                  this.customers = this.currentUser.is_hr ? response.data : response.data.customers;
                  this.currentUser.customers = linkedCustomers;
                  if(!this.currentUser.is_hr){
                    this.currentDomain = response.data.domain;
                  }
                  if(this.customers.length > 0 && !this.currentCostumer){
                    this.currentCostumer = this.customers[0].id;
                    this.currentCostumerName = this.customers[0].name;
                    store.dispatch('setCustomer', this.currentCostumer)
                  }
                  this.getCompanies(false, shouldReload);
              }).catch(error => {
                  console.log('error', error)
              });
            }
          }).catch(error => {
            console.log(window.location.pathname)
            console.log('login-check error', error)
            store.dispatch('userLogout')
            if (window.location.pathname != '/') {
              router.push('/')
            }
            this.authStatus = 'unauthorized'
          })
        }
        
      },
      getCompanies(autoGetFirstOne, shouldReload = true){
        window.currentCustomer = this.customers.find(cust => cust.id === this.currentCostumer);
        this.companies = [];
        companyService.getCompaniesByCustomer(this.currentCostumer).then(response => {
            this.companies = response.data;
            if(this.companies.length > 0 && (autoGetFirstOne || !this.currentCompany)){
              this.currentCompany = this.companies[0].id;
              this.currentCompanyName = this.companies[0].name;
              this.setCompany(this.companies[0], true);
            }
            else{
              let company = response.data.find(c => c.id == this.currentCompany);
              if(this.currentCompany && !company){
                company = response.data[0];
              }
              else if(!company){
                company = response.data[0];
                this.currentCompany = company.id;
                this.currentCompanyName = company.name;
              }
              
              this.setCompany(company, shouldReload);
            }
        }).catch(error => {
            console.log('error', error)
        });
      },
      setCompany(setcompany = null, shouldReload = true){
        try{
          const company = setcompany ? setcompany : this.companies.find(c => c.id == this.currentCompany);
          const customer = this.customers.find(c => c.id == this.currentCostumer);
          if(company){
            this.currentCompanyName = company.name;
            store.dispatch('setFunctions', company.debtor.functions);
            store.dispatch('setDepartments', company.debtor.departments);
            store.dispatch('setDefaultSchedule', company.default_schedule);
            const el = document.getElementById('sync-func-button');
            if(el && shouldReload) el.click();
          }
          if(customer){
            this.currentCostumerName = customer.name;
          }
        }
        catch(e){
          this.retroactivelySetFunctions = true;
        }
        if(shouldReload){
          store.dispatch('setCompany', this.currentCompany).then(r => this.setEmployeeOverview());
          store.dispatch('setCustomer', this.currentCostumer).then(r => this.setEmployeeOverview());
          store.dispatch('setCompanyName', this.currentCompanyName).then(r => this.setEmployeeOverview());
          store.dispatch('setCustomerName', this.currentCostumerName).then(r => this.setEmployeeOverview());
        }
      },
      setEmployeeOverview(){
        this.dispatchesFired ++;
        if(this.dispatchesFired >= this.amountOfDispatches){
          this.dispatchesFired = 0;
          const el = document.getElementById('inv_button');
          if(el) el.click();
        }
      },
      checkIfEmployeeIsFillingInNAW(){
        if(this.$route.name === "edit_employee_naw"){
          return true;
        }
        return window.location.pathname.includes("edit-employee-naw") && window.location.search.includes("?token=");
      },
      checkIfResettingPassword(){
        return this.$route.name === "password_reset";
      }
    },
    mounted () {
      //check login
      const userStorageData = window.localStorage.getItem("user");
      if(userStorageData){
        try{
          const jsonUserData = JSON.parse(userStorageData);
          if(jsonUserData.token && jsonUserData.token_expiration < new Date().toUTCString() && localStorage.getItem('authHeader')){
            this.authStatus = "logged_in";
            http.defaults['headers']['Authorization'] = localStorage.getItem('authHeader');
          }
          else{
            localStorage.removeItem('authHeader');
          }
        }
        catch(e){
          console.log(e);
        }
      }
      this.checkingForStore = true;
      let amountOfAttempts = 0;
      let maxAttempts = 10;
      const storeInterval = window.setInterval(() => {
        if(!this.checkingForStore){
          window.clearInterval(storeInterval);
          return;
        }
        if(this.checkIfEmployeeIsFillingInNAW()){
          this.checkingForStore = false;
          return;
        }
        if(this.checkIfResettingPassword()){
          router.push('/');
          this.checkingForStore = false;
          return;
        }
        if(!store.getters.userId){
          amountOfAttempts++;
          if(amountOfAttempts <= maxAttempts) return;
        }
        this.checkingForStore = false;
        if(store.getters.getCurrentCustomerId){
          this.currentCostumer = store.getters.getCurrentCustomerId
          this.currentCostumerName = store.getters.getCurrentCustomerName
        }
        if(store.getters.getDomain){
          this.currentDomain = store.getters.getDomain
        }
        if(store.getters.getCurrentCompanyId){
          this.currentCompany = store.getters.getCurrentCompanyId
          this.currentCompanyName = store.getters.getCurrentCompanyName
        }
        this.setCompany();

        const authHeader = store.getters.getAuthHeader;
        http.defaults['headers']['Authorization'] = authHeader;
        localStorage.setItem('authHeader', authHeader);
        this.checkCredentials(false);
      }, 100);
    }
  }

  http.interceptors.response.use(function (response) {
    return response
  }, function (error) {
    console.log('error occurred!', error.toJSON());
    
    if (error.response.status === 401) {
      if(!this || this.checkIfEmployeeIsFillingInNAW() || this.checkIfResettingPassword()){
        console.log('returning nothing');
        return;
      }
      store.dispatch('userLogout')
      router.push('/')
    }
    return Promise.reject(error)
  })

</script>

<style>
input[required]:not(.is-invalid) {
  padding-right: calc(1.5em + .75rem);
  background-image: url(/img/star-of-life-light.svg);
  background-repeat: no-repeat;
  background-position: right calc(.375em + .1875rem) center;
  background-size: calc(.3em + .375rem) calc(.75em + .375rem);
}

</style>

<style scoped>
@media (min-width: 768px) {
  .app-nav-menu {
    display: flex !important;
    flex-direction: column;
    width: 260px;
  }
  .app-main-content {
    display: flex !important;
    flex-direction: column;
  }
  .app-main-content:not(.no-sidebar){
    width: calc(100% - 276px);
    margin-left: 265px
  }
}
@media (max-width: 768px) {
  .app-nav-menu {
    display: none;
  }
  .app-main-content {
    display: flex !important;
    width: 100%
  }
}
#ibeo-main-header select{
  width: 200px;
  display: inline !important;
  margin: 0.5rem
}
</style>
